/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this data only for 18F brand.
 */

const countries = [
    {
        country_name: 'Andorra',
        url: '/andorra',
    },
    {
        country_name: 'Angola',
        url: '/international/angola-12510',
    },
    {
        country_name: 'Anguilla',
        url: '/anguilla',
    },
    {
        country_name: 'Argentina',
        url: '/argentina',
    },
    {
        country_name: 'Armenia',
        url: '/armenia',
    },
    {
        country_name: 'Aruba',
        url: '/aruba',
    },
    {
        country_name: 'Australia',
        url: '/australia',
    },
    {
        country_name: 'Austria',
        url: '/austria',
    },
    {
        country_name: 'Bahamas',
        url: '/international/bahamas-11934',
    },
    {
        country_name: 'Bahrain',
        url: '/bahrain',
    },
    {
        country_name: 'Barbados',
        url: '/barbados',
    },
    {
        country_name: 'Belgium',
        url: '/international/belgium-12400',
    },
    {
        country_name: 'Belize',
        url: '/belize',
    },
    {
        country_name: 'Bolivia',
        url: '/bolivia',
    },
    {
        country_name: 'Brazil',
        url: '/brazil',
    },
    {
        country_name: 'Brunei',
        url: '/brunei',
    },
    {
        country_name: 'Bulgaria',
        url: '/bulgaria',
    },
    {
        country_name: 'Canada',
        url: '/canada',
    },
    {
        country_name: 'Cayman Islands',
        url: '/cayman-islands',
    },
    {
        country_name: 'Chile',
        url: '/international/chile-12490',
    },
    {
        country_name: 'China',
        url: '/china',
    },
    {
        country_name: 'Colombia',
        url: '/colombia',
    },
    {
        country_name: 'Corsica',
        url: '/corsica',
    },
    {
        country_name: 'Costa Rica',
        url: '/costa-rica-12486',
    },
    {
        country_name: 'Cyprus',
        url: '/cyprus',
    },
    {
        country_name: 'Czech Republic',
        url: '/international/czechrepublic-12559',
    },
    {
        country_name: 'Denmark',
        url: '/denmark',
    },
    {
        country_name: 'Djibouti',
        url: '/djibouti',
    },
    {
        country_name: 'Dominican Republic',
        url: '/dominican-republic',
    },
    {
        country_name: 'Dominica',
        url: '/dominica',
    },
    {
        country_name: 'Dubai',
        url: '/international/united-arab-emirates-12399',
    },
    {
        country_name: 'Ecuador',
        url: '/ecuador',
    },
    {
        country_name: 'Egypt',
        url: '/international/egypt-12511',
    },
    {
        country_name: 'El Salvador',
        url: '/international/el-salvador-12487',
    },
    {
        country_name: 'England',
        url: '/england',
    },
    {
        country_name: 'Finland',
        url: '/finland',
    },
    {
        country_name: 'France',
        url: '/france',
    },
    {
        country_name: 'Germany',
        url: '/germany',
    },
    {
        country_name: 'Georgia',
        url: '/georgia',
    },
    {
        country_name: 'Gibraltar',
        url: '/gibraltar',
    },

    {
        country_name: 'Great Britain',
        url: '/international/greatbritain-12117',
    },
    {
        country_name: 'Greece',
        url: '/greece',
    },
    {
        country_name: 'Guam',
        url: '/international/guam-12485',
    },
    {
        country_name: 'Guadaloupe',
        url: '/guadaloupe',
    },
    {
        country_name: 'Guatemala',
        url: '/international/guatemala-12488',
    },
    {
        country_name: 'Guyana',
        url: '/international/guyana-12394',
    },
    {
        country_name: 'Hungary',
        url: '/hungary',
    },
    {
        country_name: 'Holland',
        url: '/netherlands',
    },
    {
        country_name: 'Hong Kong',
        url: '/hong-kong',
    },
    {
        country_name: 'Honduras',
        url: '/honduras',
    },
    {
        country_name: 'Iceland',
        url: '/iceland',
    },
    {
        country_name: 'India',
        url: '/india',
    },
    {
        country_name: 'Indonesia',
        url: '/international/indonesia-12491',
    },
    {
        country_name: 'Ireland',
        url: '/ireland',
    },
    {
        country_name: 'Israel',
        url: '/israel',
    },
    {
        country_name: 'Italy',
        url: '/italy',
    },
    {
        country_name: 'Jamaica',
        url: '/jamaica',
    },
    {
        country_name: 'Japan',
        url: '/japan',
    },
    {
        country_name: 'Jordan',
        url: '/jordan',
    },
    {
        country_name: 'Kenya',
        url: '/kenya',
    },
    {
        country_name: 'Kuwait',
        url: '/kuwait',
    },
    {
        country_name: 'Latvia',
        url: '/latvia',
    },
    {
        country_name: 'Lesotho',
        url: '/lesotho',
    },
    {
        country_name: 'Liechtenstein',
        url: '/liechtenstein',
    },
    {
        country_name: 'Lithuania',
        url: '/lithuania',
    },
    {
        country_name: 'Luxembourg',
        url: '/international/luxembourg-12401',
    },
    {
        country_name: 'Macedonia',
        url: '/macedonia',
    },
    {
        country_name: 'Malaysia',
        url: '/malaysia',
    },
    {
        country_name: 'Malta',
        url: '/malta',
    },
    {
        country_name: 'Mexico',
        url: '/mexico',
    },
    {
        country_name: 'Moldova',
        url: '/moldova',
    },
    {
        country_name: 'Montserrat',
        url: '/montserrat',
    },
    {
        country_name: 'Montenegro',
        url: '/montenegro',
    },
    {
        country_name: 'Morocco',
        url: '/morocco',
    },
    {
        country_name: 'Myanmar',
        url: '/myanmar',
    },
    {
        country_name: 'Nepal',
        url: '/nepal',
    },
    {
        country_name: 'New Zealand',
        url: '/new-zealand',
    },
    {
        country_name: 'Nicaragua',
        url: '/nicaragua',
    },
    {
        country_name: 'Northern Ireland',
        url: '/england',
    },
    {
        country_name: 'Norway',
        url: '/norway',
    },
    {
        country_name: 'Oman',
        url: '/oman',
    },
    {
        country_name: 'Pakistan',
        url: '/pakistan',
    },
    {
        country_name: 'Panama',
        url: '/panama',
    },
    {
        country_name: 'Paraguay',
        url: '/paraguay',
    },
    {
        country_name: 'Peru',
        url: '/peru',
    },
    {
        country_name: 'Philippines',
        url: '/philippines',
    },
    {
        country_name: 'Poland',
        url: '/international/poland-12111',
    },
    {
        country_name: 'Portugal',
        url: '/portugal',
    },
    {
        country_name: 'Romania',
        url: '/international/romania-12452',
    },
    {
        country_name: 'San Marino',
        url: '/san-marino',
    },
    {
        country_name: 'Saudi Arabia',
        url: '/international/saudi-arabia-12417',
    },
    {
        country_name: 'Senegal',
        url: '/senegal',
    },
    {
        country_name: 'Scotland',
        url: '/scotland',
    },
    {
        country_name: 'Singapore',
        url: '/singapore',
    },
    {
        country_name: 'Slovakia',
        url: '/slovakia',
    },
    {
        country_name: 'Slovenia',
        url: '/slovenia',
    },
    {
        country_name: 'South Africa',
        url: '/south-africa',
    },
    {
        country_name: 'Spain',
        url: '/spain',
    },
    {
        country_name: 'South Korea',
        url: '/international/south-korea-12398',
    },
    {
        country_name: 'Suriname',
        url: '/suriname',
    },
    {
        country_name: 'Sweden',
        url: '/sweden',
    },
    {
        country_name: 'Swaziland',
        url: '/swaziland',
    },
    {
        country_name: 'Switzerland',
        url: '/switzerland',
    },
    {
        country_name: 'Taiwan',
        url: '/international/taiwain-12408',
    },
    {
        country_name: 'Thailand',
        url: '/international/thailand-12411',

    },
    {
        country_name: 'Trinidad',
        url: '/trinidad',
    },
    {
        country_name: 'Tunisia',
        url: '/international/tunisia-13354',
    },
    {
        country_name: 'Turkey',
        url: '/turkey',
    },
    {
        country_name: 'Turkmenistan',
        url: '/turkmenistan',
    },
    {
        country_name: 'United Arab Emirates',
        url: '/international/united-arab-emirates-12399',
    },
    {
        country_name: 'United Kingdom',
        url: '/england',
    },
    {
        country_name: 'United States',
        url: '/',
    },
    {
        country_name: 'Uruguay',
        url: '/uruguay',
    },
    {
        country_name: 'Uzbekistan',
        url: '/uzbekistan',
    },
    {
        country_name: 'Venezuela',
        url: '/venezuela',
    },
    {
        country_name: 'Vietnam',
        url: '/international/vietnam-12532',
    },
    {
        country_name: 'Wales',
        url: '/england',
    },
];

const getCountryURL = (countryName, currentPath) => {
    const countryDetails = countries.find((country) => country.country_name.toLowerCase() === countryName?.toLowerCase());

    if (!countryName || !countryDetails || !currentPath || Object.keys(countryDetails).length === 0) {
        return '/';
    }

    // if on an international homepage clicking the logo redirects to US homepage
    if (currentPath === countryDetails.url) {
        return '/';
    }

    return countryDetails.url;
};

export {
    getCountryURL,
};

export default {};
