/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, func } from 'prop-types';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

export const TrackOrders = ({ image, trackEvent }) => (
    <>
        <a
            href="/account/orders"
            data-analytics-id="track-order-track-order-link"
            id="hp-my-orders"
            data-test="hp-my-orders-icon"
            name="myOrdersIcon"
            onClick={() => {
                let origin = '';
                if (typeof window !== 'undefined' && window.location && window.location.origin) {
                    origin = window.location.origin;
                }
                trackEvent({
                    eventCategory: 'Header',
                    eventAction: 'My Orders',
                    eventLabel: '<<pageType>>',
                    link_text: 'My Orders',
                    header_section: 'Core Browsing Header',
                    link_url: `${origin}/account/orders`,
                });
            }}
        >
            <img
                src={`${image}?height=25&width=44`}
                alt="My Orders Icon"
                aria-hidden="true"
                role="presentation"
                height="25"
                width="31"
            />
            <div>My Orders</div>
        </a>
    </>
);

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

TrackOrders.propTypes = {
    image: string.isRequired,
    trackEvent: func.isRequired,
};

export default connect(null, mapDispatchToProps)(TrackOrders);
