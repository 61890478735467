/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import styleFromAttributes from './styleFromAttributes';

// TODO: create and use resolveColor to lookup theme colors
// TODO: create and use resolveFont to resolve theme fonts

// unpack the attributes from Contentstack "Menu Link Group" content type
const extractCssFromArray = (attr) => {
    const attributes = {};

    if (!attr.length > 0) {
        return attributes;
    }
    attr.forEach((a) => {
        switch (Object.keys(a)[0]) {
            case 'font_family':
                attributes.fontFamily = a.font_family.font_family;
                break;
            case 'text_color':
                attributes.textColor = a.text_color.color.color; // TODO: lookup by theme if specified
                break;
            case 'pixel_text_size':
                attributes.fontSize = `${a.pixel_text_size.number}px`;
                break;
            case 'weight':
                attributes.weight = a.weight.weight;  // 'normal' or 'bold' or ?
                break;
            case 'background_color':
                attributes.backgroundColor = a.background_color.background_color.color;
                break;
            case 'text_underline':
                if (a.text_underline) {
                    attributes.textDecoration = 'underline';
                }
                break;
            case 'bottom_line_style':
                attributes.borderBottomColor = a.bottom_line_style.color.color;
                attributes.borderBottomWidth = a.bottom_line_style.thickness;
                break;
            case 'case':
                switch (a.case.case) {
                    case 'Mixed':
                        attributes.textTransform = 'none';
                        break;
                    case 'Capitalize':
                        attributes.textTransform = 'capitalize';
                        break;
                    case 'ALL CAPS':
                        attributes.textTransform = 'uppercase';
                        break;
                    default:
                        // TODO log a warning here
                }
                break;
            default:
                break;   // TODO: put warning here
        }
    });

    return attributes;
};

export function extractDropDownAttributes(dropDownAttributes) {
    const attributes = {};
    const dropDownAttributesKeys = Object.keys(dropDownAttributes || {});

    if (!dropDownAttributes || !dropDownAttributesKeys.length > 0) {
        return attributes;
    }

    dropDownAttributesKeys.forEach((key) => {
        switch (key) {
            case 'default_child_color':
                attributes.backgroundColor = dropDownAttributes[key].color;
                break;
            default:
                break;   // TODO: put warning here
        }
    });

    return styleFromAttributes(attributes);
}

export function extractHoverAttributes(hoverAttributes) {
    let attributes = {};
    const hoverAttributeKeys = Object.keys(hoverAttributes || {});

    if (!hoverAttributes || !hoverAttributeKeys.length > 0) {
        return attributes;
    }

    hoverAttributeKeys.forEach((key) => {
        switch (key) {
            case 'style_when_hovered':
                attributes = { ...attributes, ...extractCssFromArray(hoverAttributes[key]) };
                break;
            default:
                break;   // TODO: put warning here
        }
    });

    return styleFromAttributes(attributes);
}

export default function extractMenuAttributes(menuAttributes) {
    let attributes = {};
    const menuAttributesKeys = Object.keys(menuAttributes || {});

    if (!menuAttributes || !menuAttributesKeys.length > 0) {
        return attributes;
    }

    menuAttributesKeys.forEach((key) => {
        switch (key) {
            case 'background_color':
                attributes.backgroundColor  = menuAttributes[key].color;
                break;
            case 'style_profile':
                attributes.styleProfile = menuAttributes[key];
                break;
            case 'menu_height':
                attributes.height = menuAttributes[key];
                break;
            case 'menu_text_styling':
                attributes = { ...attributes, ...extractCssFromArray(menuAttributes[key]) };
                break;
            default:
                break;   // TODO: put warning here
        }
    });

    return  styleFromAttributes(attributes);
}
