/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Given attributes unpacked by extractMenuAttributes, derive JSS style settings.
// e.g.   <div class={classes.someclass} style={itemStyleOverrides}>
export default function styleFromAttributes(attributes) {
    let itemStyleOverrides = {};
    if (!attributes) {
        return itemStyleOverrides;
    }

    itemStyleOverrides = {
        ...attributes,
    };

    if (attributes.textColor) {
        itemStyleOverrides.color = attributes.textColor;
        delete itemStyleOverrides.textColor;
    }
    if (attributes.weight) {
        itemStyleOverrides.fontWeight = attributes.weight;
        delete itemStyleOverrides.weight;
    }

    return itemStyleOverrides;
}
